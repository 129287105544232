@font-face {
    font-family: Open Sans;
    src: url('../assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf');
}

$primary-gradient-active: linear-gradient(#004CDD, #004CDD, #538EFF);
$primary-gradient-default: linear-gradient(#000638);
$tab-text-default: #99BFFD;
$tab-text-active: #FFFFFF;
$yellow: #FFFFFF;
$font-family: 'Open Sans', sans-serif;