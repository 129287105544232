html {
    min-height: -webkit-calc(100% - 0px);
}

body {
    background: url("../assets/img/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $font-family;
    font-size: 14px;
}

.brand-nav-tabs {
    border-bottom: none;
    .nav-link {
        border: none;
        background: $primary-gradient-default;
        border-radius: 0;
        margin-bottom: 0;
        height: 50px;
        color: $tab-text-default;
        line-height: 1;
        &.active {
            background: $primary-gradient-active;
            color: $tab-text-active;
            border: none;
        }
        &:hover,
        &:focus {
            border: none;
        }
    }
}

.tutorial-list {
    padding: 1rem;
}

.tutorial-card {
    background: linear-gradient(#0052DB, #00296E);
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;

    .tutorial-img {
        width: 50%;
    }

    .tutorial-content {
        color: #fff;
        line-height: 1.4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 1rem;

        .tutorial-wrapper {
            display: flex;
        }

        .number {
            background: linear-gradient(180deg, #ED7620, #E54F00, #B23500, #DD7700);
            border: 1px solid $yellow;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 85%;
            font-weight: bold;
            margin-right: 0.5rem;
        }

        .tutorial-desc {
            flex: 1;
            font-size: 0.8rem;

            @media(max-width: 320px){
                line-height: 1.1;
            }
        }
    }
}

.badge-gold {
    background: linear-gradient(#ba8519, #be8c1f, #dcb64e, #ffca00);
    border: 2px solid $yellow;
    padding: 0.1rem 0.6rem;
    border-radius: 1rem;
    font-size: 80%;
    margin-bottom: 0.4rem;
    color: #FFFFFF;
    font-weight: bold;
}

.bg-primary-gradient {
    background: linear-gradient(#ffff00, #aa54f4 2%, #9e2fdd 25%, #6f13a4 78%);
}

.menu-list {
    padding: 1rem;
    .menu-item {
        text-decoration: none;
        display: block;
        padding: 2px 3px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .menu-item-content {
        padding: 0.8rem;
        background: linear-gradient(0deg, #ED7620, #E54F00, #B23500, #DD7700);
        border-radius: 10px;
        text-decoration: none;
        color: $yellow;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .menu-item-content-inner {
            flex: 1;
            line-height: 1.1;
        }

        img {
            background: #B23500;
            border: 1px solid #DD7700;
            border-radius: 10px;
            width: 75px;
            margin-right: 0.7rem;
        }

        .title {
            font-size: 1rem;
        }

        .desc {
            color: #FFFFFF;
        }
    }
}

.badge-beta {
    background: transparent linear-gradient(180deg, #538EFF 0%, #004CDD 17%, #002C82 52%, #004CDD 87%, #538EFF 100%) 0% 0% no-repeat padding-box;
    padding: 0 0.3rem;
    border-radius: 10px;
    font-size: 10px;
    margin-left: 0.4rem;
    font-weight: bold;
}

.bg-gold-gradient {
    background: linear-gradient(
        #f9df7b,
        #b57e10,
        #b78113,
        #be8c1f,
        #cb9d32,
        #dcb64e,
        #f2d570,
        #f9df7b,
        #fff3a6,
        #f9df7b,
        #b57e10,
        #e5c25b,
        #f9df7b
    );
}

.golden-notice {
    padding: 0.3rem;
    border-radius: 30px;
    display: block;
    text-decoration: none;

    .golden-notice-content {
        border-radius: 30px;
        background: linear-gradient(0deg, #ED7620, #E54F00, #B23500, #DD7700);
        padding: .5rem .5rem .5rem 1.3rem;
        line-height: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.text-yellow {
    color: #fff3a6;
}

.btn-rounded-purple {
    border: 1.5px solid $yellow;
    background: linear-gradient(180deg, #ED7620, #E54F00, #B23500, #DD7700);
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
}

.brand-line {
    box-shadow: 0 -1px 0 1px #1a0045;
    background-color: #b062ff;
}

.text-content {
    color: #fff;
    padding: 1rem 1rem 0 1rem;
    font-size: .8rem;
}

.rules-wrapper {
    color: #fff;
}